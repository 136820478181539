<template>
  <div>
    <Navbar></Navbar>
    <AlertPanel />
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from '@/components/layout/user/Navbar'
import AlertPanel from '@/components/layout/AlertPanel'
import Footer from '../anonymous/Footer'
export default {
  name: 'Layout',
  components: { Footer, AlertPanel, Navbar }
}
</script>

<style scoped>

</style>

<template>
  <v-footer id="footerbg" class="mt-13">
    <v-container>
      <v-row class="py-16">
        <v-col xs="12" sm="5" lg="6">
          <h3 class="display-1">Rezervační systém</h3>
          <div class="mt-6">
            <a href="tel:+42000000000">+420 000 000 000</a><br>
            <a href="mailto:test@test.cz">test@test.cz</a>
          </div>
        </v-col>
        <v-col xs="12" sm="4" lg="3">
          <h5>O společnosti</h5>
          <ul>
            <li><a href="/about">O nás</a> </li>
            <li><a>Kurzovní lístek</a></li>
            <li><a href="/contacts">Kontakty</a> </li>
            <li><a>GDPR</a></li>
          </ul>
        </v-col>
        <v-col xs="12" sm="3" lg="3">
          <h5>Důležité</h5>
          <ul>
            <li><a>Last minute</a> </li>
            <li><a>Cestovní kanceláře</a></li>
            <li><a>Cestovní pojištění</a> </li>
            <li><a>Zájezdy</a></li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
#footerbg{
  background-image: url("../../../assets/footer-bg.png");
  background-position: 50% 30%;
  object-fit: contain;
  background-size: cover;
  filter: brightness(0.9);
}
h3{
  color: white;
  font-weight: bold !important;
  font-family: 'Poppins', sans-serif;
  font-size: 35px;
}
a:hover{
  color: var(--v-hotelsOrange-base);
}
a{
  color: white !important;
  transition: 250ms;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
}
h5{
  color: var(--v-hotelsOrange-base);
  font-size: 25px;
  font-family: 'Poppins', sans-serif;
}
li{
  color: var(--v-hotelsOrange-base);
}
</style>
